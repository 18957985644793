exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lab-catalog-jsx": () => import("./../../../src/pages/lab/catalog.jsx" /* webpackChunkName: "component---src-pages-lab-catalog-jsx" */),
  "component---src-pages-lab-jsx": () => import("./../../../src/pages/lab.jsx" /* webpackChunkName: "component---src-pages-lab-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/Tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

